var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","scrollable":"","max-width":"900px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":"","height":"40px"}},[_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),(_vm.alertEnabled)?_c('alert',{staticClass:"ma-4",attrs:{"alert-msg":_vm.alertMsg,"alert-type":_vm.alertType}}):_vm._e(),_c('v-container',{staticClass:"mt-4 mb-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-data-table',{staticClass:"elevation-1",staticStyle:{"cursor":"pointer"},attrs:{"loading":_vm.loading,"headers":_vm.mainHeaders,"items":_vm.allBranches,"items-per-page":_vm.allBranches.length,"search":_vm.search,"hide-default-footer":"","dense":"","sort-by":"Id","item-key":"Id","fixed-header":"","group-by":"[RegionOfficeName]","height":"500px"},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
var group = ref.group;
var headers = ref.headers;
var toggle = ref.toggle;
var isOpen = ref.isOpen;
var items = ref.items;
return [_c('td',{staticStyle:{"background":"#F3F4F6"},attrs:{"colspan":headers.length},on:{"click":toggle}},[(isOpen)?_c('v-icon',[_vm._v(" mdi-chevron-up ")]):_c('v-icon',[_vm._v(" mdi-chevron-down ")]),_c('span',{staticStyle:{"font-weight":"bold"}},[_vm._v(" "+_vm._s(_vm.$t('region'))+": "+_vm._s(group))]),_c('v-chip',{staticClass:"ma-2 text--black",attrs:{"pill":"","x-small":"","color":"white"}},[_vm._v(" "+_vm._s(items.length)+" ")])],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('tr',{class:index === _vm.selectedRow ? 'background-color: blue' : '',style:(_vm.checkBranches(item.Id) ? 'background-color: #E0E0E0' : ''),attrs:{"dense":"","fluid":""},on:{"click":function($event){_vm.branchClicked(item), _vm.selectedRow = index}}},[_c('td',[_vm._v(" "+_vm._s(item.BranchOfficeName)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.Price)+" ")])])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('div',{attrs:{"height":"300px"}},[_c('v-img',{attrs:{"id":"rounded-card","height":"auto","src":_vm.detailedOffer.ItemImageUrl}}),_c('v-card-text',[_c('h2',{staticClass:"mb-4"},[_vm._v(" "+_vm._s(_vm.detailedOffer.ItemName)+" ")]),_c('v-row',{attrs:{"align":"center"}},[_c('v-checkbox',{staticClass:"mb-3",on:{"change":_vm.assignBranch},model:{value:(_vm.IsItAssigned),callback:function ($$v) {_vm.IsItAssigned=$$v},expression:"IsItAssigned"}}),_c('v-text-field',{staticClass:"align-self-end mr-4",attrs:{"dense":"","filled":"","rounded":"","append-icon":"mdi-currency-usd"},model:{value:(_vm.changedPrice),callback:function ($$v) {_vm.changedPrice=$$v},expression:"changedPrice"}})],1),_c('p',[_vm._v(_vm._s(_vm.detailedOffer.ItemDescription))])],1)],1)])],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"secondary",attrs:{"rounded":"","text":"","width":"110px"},on:{"click":_vm.close}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('v-btn',{staticClass:"primary",attrs:{"rounded":"","text":"","width":"110px"},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.btnName)+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }