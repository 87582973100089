<template>
  <v-alert
    :color="alertColor"
    dark
    border="left"
    dense
    width="30%"
    class="ml-4"
    elevation="2"
    dismissible
    transition="scale-transition"
  >
    <template>
      <v-icon>{{ alertIcon }}</v-icon>
    </template>
    {{ alertMsg }}
  </v-alert>
</template>

<script>
  export default {
    name: 'MaterialAlert',
    props: {
      alertMsg: {
        type: String,
        default: '',
      },
      alertType: {
        type: String,
        default: '',
      },
    },
    computed: {
      alertColor () {
        if (this.alertType === 'success') {
          return 'green darken-1'
        } else return 'red darken-3'
      },
      alertIcon () {
        if (this.alertType === 'success') {
          return 'mdi-check'
        } else return 'mdi-close'
      },
    },
  }
</script>

<style lang="sass">

  .v-alert--material
    // margin-top: 32px

    // .v-alert__icon
    //   top: -36px

    .v-alert__dismissible
      align-self: flex-start
      margin: 0 !important
      padding: 0 !important
</style>
