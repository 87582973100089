<template>
  <v-container
    fluid
    tag="section"
    class="mt-1"
  >
    <v-skeleton-loader
      v-if="loader"
      class="mx-auto"
      type="card"
    />
    <v-card v-if="!loader">
      <v-card-title class="card-title">
        <h4>{{ $t('offers') }}</h4>
        <v-spacer />
        <v-btn
          v-if="addPermission"
          color="primary"
          rounded
          small
          dark
          @click="addClick"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-card-title>
      <v-row class="pa-8">
        <v-col
          v-for="(item) in offers"
          :key="item.Id"
          v-model="selectedOffer"
          cols="12"
          sm="6"
          md="4"
          lg="3"
          xl="2"
        >
          <v-card
            height="300px"
            @click="offerDetails"
          >
            <v-img
              lazy-src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRJEK40TRPKbM5JcPw1M6F8ayHInpCGrTNSrg&usqp=CAU"
              class="white--text align-end outline-none"
              height="200px"
              transition="lazy-src"
              :src="item.ItemImageUrl"
            />
            <v-card-title>
              {{ item.ItemName }}
            </v-card-title>
            <div class="d-flex justify-space-between px-0">
              <v-card-title>
                {{ displayPrice(item) }}$
              </v-card-title>
              <v-card-actions>
                <v-icon
                  v-if="editPermission"
                  small
                  class="mr-2"
                  @click="userData.roleData.roleName === 'BRANCH_ADMIN' ? editOfferPrice(item) :editOffer(item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                  v-if="deletePermission"
                  small
                  class="mr-2"
                  @click="deleteOffer(item)"
                >
                  mdi-delete
                </v-icon>
                <v-icon
                  v-if="assignPermission"
                  small
                  class="mr-2"
                  @click="showBranches(item)"
                >
                  mdi-storefront
                </v-icon>
              </v-card-actions>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <manage-customer-offer
      v-if="dialog"
      :dialog="dialog"
      :dialog-type="dialogType"
      :clicked-offer="detailedOffer"
      @offer-dialog-closed="dialogClosed"
    />
    <!-- <edit-offer
      v-if="dialogEdit"
      :dialog="dialogEdit"
      :clicked-offer="detailedOffer"
      @edit-dialog-closed="dialogClosed"
    /> -->
    <assign-branches
      v-if="branchesDialog"
      :dialog="branchesDialog"
      :item-id="itemID"
      :detailed-offer="detailedOffer"
      @branch-office-dialog-closed="dialogClosed"
    />
    <v-dialog
      v-model="dialogDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ $t('areYouSureDeleteOffer') }}
        </v-card-title>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="closeDelete"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="deleteOfferConfirm"
          >
            {{ $t('ok') }}
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="offerDetailsDialog"
      max-width="500px"
    >
      <v-card>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="offerDetailsDialog = false"
          >
            {{ $t('cancel') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="editOfferPriceDialog"
      max-width="500px"
    >
      <v-card>
        <v-toolbar
          color="primary"
          dark
          height="40px"
        >
          <h4>
            {{ $t('editItemPrice') }}
          </h4>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            v-model="newItemPrice"
            :label="$t('editItemPrice')"
            outlined
            dense
            type="number"
            prepend-inner-icon="mdi-cash"
          />
        </v-card-text>
        <v-card-actions class="d-flex justify-space-around">
          <v-btn
            color="blue darken-1"
            text
            @click="editOfferPriceDialog = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="setNewPrice"
          >
            {{ $t('set') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import { mapState } from 'vuex'
  import { checkPermission } from '@/router/permissions'
  import ManageCustomerOffer from '../Dialogs/ManageCustomerOffer.vue'
  // import EditOffer from '../Dialogs/EditCustomerOffer.vue'
  import AssignBranches from '../Dialogs/AssignBranchesDialog.vue'
  // import OfferDetailsCard from '../Cards/OfferDetailsCard.vue'

  export default {
    components: {
      ManageCustomerOffer,
      // EditOffer,
      AssignBranches,
      // OfferDetailsCard,
    },
    data: () => ({
      index: 0,
      dialogType: null,
      newItemPrice: null,
      flag: false,
      editOfferPriceDialog: false,
      offerDetailsDialog: false,
      search: '',
      currentItem: {},
      colorToDisplay: '',
      loader: true,
      dialog: false,
      dialogEdit: false,
      dialogDelete: false,
      branchesDialog: false,
      itemID: -1,
      offerToBeDelete: -1,
      filterOffersStatus: 'All',
      footerProps: { 'items-per-page-options': [-1, 5, 10, 15] },
      offers: [],
      verifiers: [],
      selectedOffer: {},
      expanded: [],
    }),
    computed: {
      ...mapState({
        offersFromRepo: (state) => state.customerOfferedItems.customerOfferedItems,
        detailedOffer: (state) => state.customerOfferedItems.selectedCustomerOfferedItems,
        userData: (state) => state.authentication.userData,
        offerPhoto: (state) => state.customerOfferedItems.offerPhoto,
        coiBranches: (state) => state.coiBranchesPivot.coiBranches,
        offerStatuses: (entytiStatuses) => entytiStatuses.verifier.offerStatuses,
      }),
      headers () {
        return [
          { text: this.$t('Id'), value: 'Id', align: 'start' },
          { text: this.$t('name'), value: 'ItemName' },
          { text: this.$t('itemUnit'), value: 'ItemUnit' },
          { text: this.$t('itemPricePerUnit'), value: 'ItemPricePerUnit' },
          { text: this.$t('status'), value: 'Status' },
          { text: this.$t('branches'), value: 'menu', sortable: false, align: 'center' },
          { text: this.$t('actions'), value: 'actions', sortable: false, align: 'center' },
        ]
      // TODO FIX ROLE DATA TO ALL MODELS
      },
      filteredOffers () {
        let offers = []
        switch (this.filterOffersStatus) {
          case 'All':
            offers = this.offers
            break
          case 'Active':
            offers = this.offers.filter(o => o.Status === 'Active')
            break
          case 'Inactive':
            offers = this.offers.filter(o => o.Status === 'Inactive')
            break
          default:
            offers = this.offers
            break
        }
        return offers
      },
      addPermission () {
        return checkPermission(this.userData.roleData.roleName, 'offers', 'add')
      },
      editPermission () {
        return checkPermission(this.userData.roleData.roleName, 'offers', 'edit')
      },
      deletePermission () {
        return checkPermission(this.userData.roleData.roleName, 'offers', 'delete')
      },
      assignPermission () {
        return checkPermission(this.userData.roleData.roleName, 'offers', 'assign')
      },
      isItBranch () {
        if (this.userData.roleData.roleName === 'BRANCH_ADMIN') {
          return true
        } else {
          return false
        }
      },
    },
    async mounted () {
      await this.init()
    },
    methods: {
      displayPrice (item) {
        let price = ''
        if (this.isItBranch) {
          for (const coi of this.coiBranches) {
            if (item.Id === coi.ItemId) {
              price = coi.ItemPricePerUnitOverride
              break
            }
          }
        } else {
          price = item.ItemPricePerUnit
        }
        return price
      },
      async init () {
        await this.getCoiBranches()
        await this.getOffers()
      },
      editOfferPrice (item) {
        this.selectedOffer = item
        this.flag = true
        this.editOfferPriceDialog = true
      },
      offerDetails () {
        if (!this.flag) {
          this.offerDetailsDialog = true
        }
        this.flag = false
      },
      async getCoiBranches () {
        await this.$store.dispatch('coiBranchesPivot/getCoiBranches')
      },
      colorOfBadge: (val) => {
        if (val === 0) {
          return 'error'
        }
        return 'green'
      },
      addClick () {
        this.$nextTick(() => {
          this.dialogType = 'addCustomerOffer'
          this.dialog = true
        })
      },
      async getOffers () {
        await this.$store.dispatch('customerOfferedItems/getCustomerOfferedItems')
        this.offers = this.offersFromRepo
        this.loader = false
        await this.getOfferPhoto()
      },
      async getOfferPhoto () {
        this.asyncForEach(this.offers, async (offer) => this.$store.dispatch('customerOfferedItems/getOfferPhoto', offer.Id).then(() => {
          offer.ItemImageUrl = 'data:image/jpeg;base64,' + this.offerPhoto.data
        }),
        )
      },
      async asyncForEach (array, callback) {
        for (let index = 0; index < array.length; index++) {
          await callback(array[index], index, array)
        }
      },
      async editOffer (offer) {
        this.flag = true
        await this.$store.dispatch('customerOfferedItems/getCustomerOfferedItemsById', offer.Id).then(() => {
          this.dialogType = 'editCustomerOffer'
          this.dialog = true
        })
      },
      async setNewPrice () {
        const body = {
          customerId: this.selectedOffer.CustomerId,
          itemId: this.selectedOffer.Id,
          branchOfficeId: this.userData.branchOfficeId,
          itemPricePerUnitOverride: this.newItemPrice,
        }
        for (const coi of this.coiBranches) {
          if (this.userData.branchOfficeId === coi.BranchOfficeId && this.selectedOffer.Id === coi.ItemId) {
            body.id = coi.Id
            break
          }
        }
        await this.$store.dispatch('coiBranchesPivot/updateCoiBranches', body).then(() => {
          this.editOfferPriceDialog = false
        })
        await this.init()
      },
      deleteOffer (offer) {
        this.flag = true
        this.offerToBeDelete = offer.Id
        this.dialogDelete = true
      },
      dialogClosed () {
        this.dialogEdit = false
        this.dialog = false
        this.branchesDialog = false
        this.getOffers()
      },
      closeDelete () {
        this.offerToBeDelete = -1
        this.dialogDelete = false
      },
      deleteOfferConfirm () {
        this.$store.dispatch('customerOfferedItems/deleteCustomerOfferedItems', this.offerToBeDelete)
          .then(res => {
            if (res.resFlag) {
              this.dialogDelete = false
              this.getOffers()
            }
          })
      },
      async showBranches (item) {
        this.flag = true
        await this.$store.dispatch('customerOfferedItems/getCustomerOfferedItemsById', item.Id)
        this.branchesDialog = true
      },
    },
  }
</script>

<style>
.v-btn--is-elevated.v-btn--fab {
  -webkit-box-shadow: none;
  box-shadow: none;
}
</style>
