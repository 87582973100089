<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    max-width="790px"
  >
    <v-card>
      <v-card-title class="d-flex align-center justify-space-between">
        <h5 v-if="dialogType === 'editCustomerOffer'">
          {{ $t('editOffer') }}
        </h5>
        <h5 v-else>
          {{ $t('newOffer') }}
        </h5>
        <v-icon
          color="white"
          @click="close"
        >
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text>
        <v-stepper
          v-model="stepperModel"
          vertical
        >
          <v-stepper-step
            :complete="stepperModel > 1"
            step="1"
            editable
          >
            Offer details
            <small>Content must be filled</small>
          </v-stepper-step>

          <v-stepper-content step="1">
            <v-container class="ml-1 mr-6">
              <validation-provider
                v-slot="{ errors }"
                :name="$t('itemName')"
                rules="required"
              >
                <v-text-field
                  v-model="itemName"
                  :label="$t('itemName')"
                  :error-messages="errors"
                  outlined
                  dense
                />
              </validation-provider>

              <validation-provider
                v-slot="{ errors }"
                :name="$t('itemUnit')"
                rules="required"
              >
                <v-text-field
                  v-model="itemUnit"
                  :label="$t('itemUnit')"
                  :error-messages="errors"
                  outlined
                  dense
                />
              </validation-provider>

              <validation-provider
                v-slot="{ errors }"
                :name="$t('itemPricePerUnit')"
                rules="required"
              >
                <v-text-field
                  v-model="itemPricePerUnit"
                  type="number"
                  :label="$t('itemPricePerUnit')"
                  :error-messages="errors"
                  outlined
                  dense
                />
              </validation-provider>
              <v-file-input
                show-size
                counter
                outlined
                dense
                prepend-icon=""
                prepend-inner-icon="mdi-paperclip"
                multiple
                label="File input"
                @change="image"
              />
              <!-- <validation-provider
                v-slot="{ errors }"
                :name="$t('itemImageUrl')"
                rules="required"
              >
                <v-text-field
                  v-model="itemImageUrl"
                  :label="$t('itemImageUrl')"
                  :error-messages="errors"
                  outlined
                  dense
                />
              </validation-provider> -->
              <validation-provider>
                <v-select
                  v-model="statusId"
                  :items="offerStatuses"
                  item-text="ShortDescription"
                  item-value="LowValue"
                  :label="$t('status')"
                  outlined
                  dense
                />
              </validation-provider>
              <v-textarea
                v-model="itemDescription"
                outlined
                name="input-7-4"
                :label="$t('itemDescription')"
              />
            </v-container>
          </v-stepper-content>
        </v-stepper>
      </v-card-text>
      <v-card-actions v-if="stepperModel === 1">
        <v-spacer />
        <v-btn
          rounded
          text
          class="secondary"
          width="110px"
          @click="close"
        >
          <v-icon left>
            mdi-cancel
          </v-icon>
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          rounded
          text
          class="primary"
          width="110px"
          @click="save"
        >
          <v-icon left>
            mdi-content-save
          </v-icon>
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    props: {
      dialog: {
        type: Boolean,
      },
      dialogType: {
        type: String,
        default: '',
      },
      clickedOffer: {
        type: Object,
        default: function () {
          return {}
        },
      },
    },
    data () {
      return {
        stepperModel: 1,
        fileChanged: false,
        selectableData: {
          customers: [],
        },
        itemName: '',
        itemImageUrl: '',
        fileData: [],
        itemDescription: '',
        itemImage: '',
        itemUnit: '',
        itemPricePerUnit: 0,
        statusId: -1,
        customerId: -1,
      }
    },
    computed: {
      ...mapState({
        customers: (state) => state.customer.customers,
        userData: (state) => state.authentication.userData,
        offerStatuses: (entytiStatuses) => entytiStatuses.verifier.offerStatuses,
      }),
      status () {
        return [
          { name: this.$t('active'), value: 1 },
          { name: this.$t('inactive'), value: 0 },
        ]
      },
    },
    async mounted () {
      this.getDetails()
    },
    methods: {
      async getDetails () {
        if (this.dialogType === 'editCustomerOffer') {
          this.itemName = this.clickedOffer.ItemName
          this.itemDescription = this.clickedOffer.ItemDescription
          // this.itemImageUrl = this.clickedOffer.ItemImageUrl ... change this to clickedOffer image data
          this.itemUnit = this.clickedOffer.ItemUnit
          this.itemPricePerUnit = this.clickedOffer.ItemPricePerUnit
          this.statusId = this.clickedOffer.Status
          this.customerId = this.clickedOffer.CustomerId
        }
      },
      image (file) {
        if (file.length === 0) return
        this.fileChanged = true
        var formData = new FormData()
        formData.append('FormFile', file[0])
        formData.append('FileName', file[0].name)
        this.fileData = formData
        this.imgToBase64(file[0])
      },
      imgToBase64 (file) {
        var reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function () {
          this.returnimage = reader.result
        }
        reader.onerror = function (error) {
          error = null
        }
      },
      async upload (id) {
        const body = {
          formData: this.fileData,
          id: id,
        }
        await this.$store.dispatch('customerOfferedItems/uploadOfferImage', body)
      },
      close () {
        this.$emit('offer-dialog-closed')
      },
      async save () {
        const body = {
          itemName: this.itemName,
          itemDescription: this.itemDescription,
          itemImageUrl: this.itemImageUrl,
          itemUnit: this.itemUnit,
          itemPricePerUnit: this.itemPricePerUnit,
          customerId: this.userData.customerId,
          status: this.statusId,
        }
        if (this.dialogType === 'editCustomerOffer') {
          body.id = this.clickedOffer.Id
          await this.$store.dispatch('customerOfferedItems/updateCustomerOfferedItems', body)
            .then(res => {
              if (res.resFlag) {
                if (this.fileChanged) {
                  this.upload(this.clickedOffer.Id)
                }
                this.close()
              }
            })
        } else {
          await this.$store.dispatch('customerOfferedItems/createCustomerOfferedItems', body)
            .then(res => {
              if (res.resFlag) {
                this.upload(res.Id)
                this.close()
              }
            })
        }
      },
    },
  }
</script>

<style scoped>
.v-card__title {
  background: #16578f;
  color: #ffffff;
  height: 40px;
  padding: 5px 20px;
}
.v-dialog > .v-card > .v-card__title {
  background: #16578f;
  color: #ffffff;
  height: 40px;
  padding: 5px 20px;
}
</style>
