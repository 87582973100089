<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    max-width="900px"
  >
    <v-card>
      <v-toolbar
        color="primary"
        dark
        height="40px"
      >
        <v-spacer />
        <v-btn
          icon
          @click="close"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <!-- <v-alert
        :type="alertType"
        class="ma-4"
        :value="alert"
      >
        {{ alertMessage }}
      </v-alert> -->
      <alert
        v-if="alertEnabled"
        :alert-msg="alertMsg"
        :alert-type="alertType"
        class="ma-4"
      />
      <v-container
        class="mt-4 mb-4"
      >
        <v-row>
          <v-col
            cols="6"
          >
            <v-data-table
              :loading="loading"
              :headers="mainHeaders"
              :items="allBranches"
              :items-per-page="allBranches.length"
              :search="search"
              hide-default-footer
              dense
              sort-by="Id"
              item-key="Id"
              fixed-header
              style="cursor:pointer"
              class="elevation-1"
              group-by="[RegionOfficeName]"
              height="500px"
            >
              <template v-slot:[`group.header`]="{ group, headers, toggle, isOpen, items }">
                <td
                  :colspan="headers.length"
                  style="background: #F3F4F6;"
                  @click="toggle"
                >
                  <v-icon v-if="isOpen">
                    mdi-chevron-up
                  </v-icon>
                  <v-icon v-else>
                    mdi-chevron-down
                  </v-icon>
                  <span style="font-weight: bold;"> {{ $t('region') }}: {{ group }}</span>
                  <v-chip
                    pill
                    x-small
                    color="white"
                    class="ma-2 text--black"
                  >
                    {{ items.length }}
                  </v-chip>
                </td>
              </template>
              <template v-slot:item="{ item , index }">
                <tr
                  dense
                  fluid
                  :style="checkBranches(item.Id) ? 'background-color: #E0E0E0' : ''"
                  :class="index === selectedRow ? 'background-color: blue' : ''"
                  @click="branchClicked(item), selectedRow = index"
                >
                  <td>
                    {{ item.BranchOfficeName }}
                  </td>
                  <td>
                    {{ item.Price }}
                  </td>
                </tr>
              </template>
              <template v-slot:top>
                <v-toolbar
                  flat
                  color="white"
                >
                  <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    :label="$t('search')"
                    single-line
                    hide-details
                  />
                </v-toolbar>
              </template>
            </v-data-table>
          </v-col>
          <v-col
            cols="6"
          >
            <div
              height="300px"
            >
              <v-img
                id="rounded-card"
                height="auto"
                :src="detailedOffer.ItemImageUrl"
              />
              <v-card-text>
                <h2 class="mb-4">
                  {{ detailedOffer.ItemName }}
                </h2>
                <!-- <div
                  v-if="showPrice"
                  class="d-flex justify-between mb-4"
                >

                </div> -->
                <v-row align="center">
                  <v-checkbox
                    v-model="IsItAssigned"
                    class="mb-3"
                    @change="assignBranch"
                  />
                  <v-text-field
                    v-model="changedPrice"
                    dense
                    class="align-self-end mr-4"
                    filled
                    rounded
                    append-icon="mdi-currency-usd"
                  />
                </v-row>
                <p>{{ detailedOffer.ItemDescription }}</p>
              </v-card-text>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-card-actions>
        <v-spacer />
        <v-btn
          rounded
          text
          class="secondary"
          width="110px"
          @click="close"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          rounded
          text
          class="primary"
          width="110px"
          @click="save"
        >
          {{ btnName }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapState } from 'vuex'
  import Alert from '../component/Alert.vue'
  export default {
    components: {
      Alert,
    },
    props: {
      dialog: {
        type: Boolean,
      },
      itemId: {
        type: Number,
        default: function () {
          return 0
        },
      },
      detailedOffer: {
        type: Object,
        default: function () {
          return {}
        },
      },
    },
    data () {
      return {
        alertEnabled: false,
        selectedRow: null,
        alertMsg: '',
        alertType: '',
        branchWasSelected: false,
        showPrice: false,
        loading: true,
        changedPrice: null,
        search: '',
        allBranches: [],
        branches: [],
        data: [],
        selectedBranch: {},
        btnName: 'Assign',
        IsItAssigned: false,
        assignNewBranch: false,
        deAssignBranch: false,
      }
    },
    computed: {
      ...mapState({
        selectedCustomerOfferedItems: (state) => state.customerOfferedItems.selectedCustomerOfferedItems,
        branchesFromRepo: (state) => state.branchOffice.branchOffices,
        coiBranches: (state) => state.coiBranchesPivot.coiBranches,
        userData: (state) => state.authentication.userData,
      }),
      mainHeaders () {
        return [
          { text: this.$t('branchOfficeName'), value: 'BranchOfficeName' },
          { text: this.$t('price'), value: 'Price' },
        ]
      },
    },
    async mounted () {
      await this.getAllBranches()
      await this.getBranches()
      await this.getCoiBranches()
    },
    methods: {
      close () {
        this.$emit('branch-office-dialog-closed')
      },
      sendChanges () {
        this.showPrice = false
      },
      assignBranch () {
      },
      branchClicked (branch) {
        this.changedPrice = null
        if (this.checkBranches(branch.Id)) {
          this.btnName = 'Update'
          this.IsItAssigned = true
        } else {
          this.btnName = 'Assign'
          this.IsItAssigned = false
        }
        this.showPrice = true
        this.branchWasSelected = true
        this.selectedBranch = branch
        for (const coi of this.coiBranches) {
          if (this.selectedBranch.Id === coi.BranchOfficeId && this.detailedOffer.Id === coi.ItemId) {
            this.changedPrice = coi.ItemPricePerUnitOverride
            break
          }
        }
      },
      checkBranches (id) {
        return this.branches.includes(id)
      },
      async getCoiBranches () {
        await this.$store.dispatch('coiBranchesPivot/getCoiBranches')
        this.allBranches.forEach(branch => {
          for (const coi of this.coiBranches) {
            if (branch.Id === coi.BranchOfficeId && this.detailedOffer.Id === coi.ItemId) {
              branch.Price = coi.ItemPricePerUnitOverride
              branch.Price += ' $'
            }
          }
        })
        this.loading = false
      },
      async getBranches () {
        await this.$store.dispatch('customerOfferedItems/getCustomerOfferedItemsById', this.detailedOffer.Id)
          .then(() => {
            this.selectedCustomerOfferedItems.AssignedBranches.forEach(branch => {
              this.branches.push(branch.id)
            })
          })
      },
      async getAllBranches () {
        await this.$store.dispatch('branchOffice/getBranchOffice')
          .then(() => {
            this.allBranches = this.branchesFromRepo
          })
      },
      save () {
        const body = {
          customerId: this.userData.customerId,
          itemId: this.detailedOffer.Id,
          branchOfficeId: this.selectedBranch.Id,
          itemPricePerUnitOverride: this.changedPrice !== null ? parseFloat(this.changedPrice) : this.detailedOffer.ItemPricePerUnit,
        }
        if (this.IsItAssigned) {
          for (const coi of this.coiBranches) {
            if (this.selectedBranch.Id === coi.BranchOfficeId && this.detailedOffer.Id === coi.ItemId) {
              body.id = coi.Id
              break
            }
          }
          if (body.id !== undefined) {
            this.$store.dispatch('coiBranchesPivot/updateCoiBranches', body)
              .then(() => {
                this.alertMsg = 'Updated succefully'
                this.alertType = 'success'
                this.alertEnabled = true
                this.getCoiBranches()
                window.setInterval(() => {
                  this.alertEnabled = false
                }, 3000)
                this.getBranches()
                this.showPrice = false
              }).catch(() => {
                this.alertMsg = 'Update failed'
                this.alertType = 'error'
                this.alertEnabled = true
                window.setInterval(() => {
                  this.alertEnabled = false
                }, 3000)
                this.showPrice = false
              })
          } else {
            this.$store.dispatch('coiBranchesPivot/createCoiBranches', body)
              .then(() => {
                this.alertMsg = 'Store assigned succefully'
                this.alertType = 'success'
                this.alertEnabled = true
                this.getCoiBranches()
                window.setInterval(() => {
                  this.alertEnabled = false
                }, 3000)
                this.getBranches()
                this.showPrice = false
              }).catch(() => {
                this.alertMsg = 'Assign failed'
                this.alertType = 'error'
                this.alertEnabled = true
                window.setInterval(() => {
                  this.alertEnabled = false
                }, 3000)
                this.showPrice = false
              })
          }
        } else {
          let flag = false
          for (const coi of this.coiBranches) {
            if (this.selectedBranch.Id === coi.BranchOfficeId && this.detailedOffer.Id === coi.ItemId) {
              flag = true
              break
            }
          }
          if (flag) {
            const deleteBody = {
              itemId: this.detailedOffer.Id,
              branchOfficeId: this.selectedBranch.Id,
            }
            this.$store.dispatch('coiBranchesPivot/deleteCoiByItemAndBranch', deleteBody)
              .then(() => {
                this.alertMsg = 'Store was removed succefully'
                this.alertType = 'success'
                this.alertEnabled = true
                this.getAllBranches()
                this.getBranches()
                this.getCoiBranches()
                window.setInterval(() => {
                  this.alertEnabled = false
                }, 3000)
                this.getBranches()
                this.showPrice = false
              }).catch(() => {
                this.alertMsg = 'Removing the store failed'
                this.alertType = 'error'
                this.alertEnabled = true
                window.setInterval(() => {
                  this.alertEnabled = false
                }, 3000)
                this.showPrice = false
              })
          }
        }
      },
    },
  }
</script>

<style scoped>
.v-card__title {
    background: #F3F4F6;
    color: #000;
    height: 40px;
    padding: 5px 20px;
}
.v-dialog > .v-card > .v-card__title {
    background: #F3F4F6;
    color: #000;
    height: 40px;
    padding: 5px 20px;
}
.iconStyle {
  cursor: pointer;
}
.iconStyle:hover {
  color: #16578f ;
}
#rounded-card {
  border-radius: 1%;
}
.e-grid td.e-active{
  background-color: blue;
}
</style>
